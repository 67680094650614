@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

@layer base {
  :root {
    --background: 42 100% 56.86%;
    --foreground: 0 0% 14.9%;
    --muted: 42 100% 42%;
    --muted-foreground: 0 0% 14.9%;
    --popover: 0 0% 98.04%;
    --popover-foreground: 0 0% 14.9%;
    --card: 0 0% 98.04%;
    --card-foreground: 0 0% 14.9%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --primary: 0 0% 14.9%;
    --primary-foreground: 42 100% 56.86%;
    --secondary: 0 0% 98.04%;
    --secondary-foreground: 0 0% 14.9%;
    --accent: 42 100% 56.86%;
    --accent-foreground: 0 0% 98.04%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 0 0% 100%;
    --ring: 42 100% 56.86%;
    --radius: 0.5rem;
    --destructive-darker: 0, 100%, 35%;
  }

  .dark {
    --background: 0 0% 14.9%;
    --foreground: 42 100% 56.86%;
    --muted: 42.06 0% 30%;
    --muted-foreground: 0 0% 98.04%;
    --popover: 0 0% 14.9%;
    --popover-foreground: 0 0% 98.04%;
    --card: 0 0% 14.9%;
    --card-foreground: 42 100% 56.86%;
    --border: 42 100% 56.86%;
    --input: 42 100% 56.86%;
    --primary: 42 100% 56.86%;
    --primary-foreground: 162 88% 5%;
    --secondary: 0 0% 98.04%;
    --secondary-foreground: 0 0% 14.9%;
    --accent: 42 100% 56.86%;
    --accent-foreground: 0 0% 14.9%;
    --destructive: 6 95% 52%;
    --destructive-foreground: 0 0% 100%;
    --ring: 42 100% 56.86%;
    --destructive-darker: 0, 100%, 30%;
  }
}




@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
  input::placeholder,
  textarea::placeholder {
    color: hsl(var(--input-placeholder));
  }
  ::selection {
    background-color: rgba(38, 38, 38, 0.2);
    color: #FAFAFA;
  }
}

@layer utilities {
  .transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  
  .hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.slider {
  @apply relative flex items-center select-none touch-none w-full;
}

.slider .slider-track {
  @apply relative h-2 grow rounded-full bg-ppgray/20;
}

.slider .slider-range {
  @apply absolute h-full rounded-full bg-ppgray;
}

.slider .slider-thumb {
  @apply block h-5 w-5 rounded-full border-2 border-ppgray bg-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50;
}

.custom-toast {
  border: 2px solid var(--border) !important;
  margin-bottom: 50px !important;
}

.accordion-trigger {
  transition: border-radius 0.0s;
}

.accordion-trigger[data-state='open'] {
  border-radius: 0.5rem 0.5rem 0 0;
}

.accordion-content {
  border-radius: 0 0 0.5rem 0.5rem;
  transition: border-radius 0.0s;
}

.accordion-content[data-state='closed'] ~ .accordion-trigger {
  border-radius: 0.5rem;
}

button.close-button {
  border-radius: 0.3rem; /* rounded corners */
  border-color: hsl(var(--destructive));
  background-color: hsl(var(--destructive)); /* primary background color */
  color: hsl(var(--destructive)); /* secondary text color */
  padding: 0.5rem; /* p-2 */
  color: hsl(var(--primary));
}

button.close-button:disabled {
  pointer-events: none !important;
}

button.close-button[data-state='open'] {
  background-color: var(--destructive) !important;
  color: var(--muted-foreground) !important;
}

button.close-button:hover {
  background-color: hsl(var(--destructive-darker)) !important; /* hover:bg-red-600 */
  border-color: hsl(var(--destructive-darker)) !important;
  color: hsl(var(--secondary))
}

button.close-button:active {
  background-color: hsl(var(--destructive-darker)) !important;
  color: hsl(var(--secondary) / 0.4) !important;
  border-color: hsl(var(--destructive-darker)) !important;
}


.image-close-button {
  @apply inline-flex items-center justify-center text-sm font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:text-secondary absolute right-1 top-1 w-6 h-6 p-0 bg-foreground hover:bg-destructive text-secondary rounded-sm;
}

.image-close-button:hover {
  @apply bg-destructive;
}

.image-close-button svg {
  @apply h-4 w-4;
}

.image-close-button:focus {
  outline: none;
  box-shadow: none;
}

.image-close-button:focus-visible {
  @apply ring-2 ring-offset-2 ring-ring;
}

.progress-indicator {
  background-color: var(--foreground) !important;
}

.custom-dark-skeleton {
  background-color: var(--muted-foreground) !important;
}

.animate-pulse.custom-dark-skeleton {
  background-color: var(--muted-foreground) !important;
}

.transparent-button {
  @apply border border-foreground text-foreground rounded-md px-4 py-2 transition-all;
  background-color: transparent;
}

.transparent-button:hover {
  @apply bg-foreground text-card-foreground;
}

.transparent-button:active {
  color: var(--primary);
}

.transparent-button:focus-visible {
  @apply ring-2 ring-offset-2 ring-ring;
}

.white-indicator[role="progressbar"] > div {
  background-color: var(--card-secondary) !important;
}

.dropzone-custom {
  @apply transition-all duration-200 ease-in-out;
}

.dropzone-custom:hover {
  @apply border-foreground;
}

.dropzone-custom-active {
  @apply border-foreground bg-muted;
}

.offscreen {
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.image-generator-container {
  width: 896px;
}

.vote-page-grid {
  display: grid;
  gap: 0.5rem;
}

.vote-page-popover-content {
  padding: 0;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(38, 38, 38, 0.1), 0 2px 4px -1px rgba(38, 38, 38, 0.06);
}

.poll-creator-grid {
  display: grid;
  gap: 1rem;
}

.poll-creator-grid-1 { grid-template-columns: repeat(1, 1fr); }
.poll-creator-grid-2 { grid-template-columns: repeat(2, 1fr); }
.poll-creator-grid-3 { grid-template-columns: repeat(3, 1fr); }
.poll-creator-grid-4 { grid-template-columns: repeat(4, 1fr); }
.poll-creator-grid-5 { grid-template-columns: repeat(5, 1fr); }
.poll-creator-grid-6 { grid-template-columns: repeat(6, 1fr); }

/* Add this at the end of your globals.css file */

.x-close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  border-radius: 0.125rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.x-close-button:focus {
  outline: none;
}

.x-close-button:disabled {
  pointer-events: none;
}

.x-close-button[data-state="open"] {
  background-color: hsl(var(--accent));
  color: hsl(var(--muted-foreground));
}

.x-close-button {
  background-color: hsl(var(--primary));
  color: hsl(var(--background));
  padding: 0.5rem;
}

.x-close-button:hover {
  background-color: hsl(var(--destructive));
  color: hsl(var(--secondary));
}

.x-close-button:active {
  background-color: hsl(var(--destructive-darker));
}

@font-face {
  font-family: 'Lato';
  src: url('@/fonts/Lato-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('@/fonts/Lato-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('@/fonts/Lato-Italic.ttf') format('ttf');
  font-weight: 400;
  font-style: italic;
}
